import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const DropdownMenu = ({
  alignment,
  leftPosition,
  rightPosition,
  className,
  ...props
}) => {
  const left = alignment === "left";
  const right = alignment === "right";

  return (
    <div
      style={{ zIndex: 100, transformOrigin: `top ${alignment}` }}
      className={twMerge(
        "absolute top-full w-full list-none min-w-200 bg-white shadow-lg rounded overflow-hidden z-[100] pl-0 mt-2",
        right ? rightPosition ?? "right-0" : "",
        left ? leftPosition ?? "left-0" : "",
        className,
      )}
      // CSS={{
      //   ":-webkit-scrollbar": {
      //     width: 0,
      //   },
      //   "&.menu-enter": {
      //     opacity: 0,
      //     transform: `scale(0.95)`,
      //   },
      //   "&.menu-enter-active": {
      //     opacity: 1,
      //     transform: "scale(1)",
      //     transition: "opacity 150ms ease-in-out, transform 50ms ease-in-out",
      //   },
      //   "&.menu-exit": {
      //     opacity: 1,
      //   },
      //   "&.menu-exit-active": {
      //     opacity: 0,
      //     transform: "scale(0.95)",
      //     transition: "opacity 150ms ease-in-out, transform 50ms ease-in-out",
      //   },
      // }}
      {...props}
    />
  );
};

DropdownMenu.displayName = "DropdownMenu";

DropdownMenu.defaultProps = {
  alignment: "right",
};

DropdownMenu.propTypes = {
  rightPosition: PropTypes.number,
  leftPosition: PropTypes.number,
  alignment: PropTypes.oneOf(["left", "right"]),
  className: PropTypes.string,
};

export default DropdownMenu;
