import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import useTranslation from "sholdi-hooks/useTranslation";

import Link from "sholdi-primitives/atoms/Link";

import Dropdown from "../../../molecules/Dropdown";
import User from "sholdi-icons/User";

const LINK_STYLE = "block w-full px-4 py-2 text-gray-800 font-semibold";

const UserCtas = ({ className, ...props }) => {
  const { t } = useTranslation();
  return (
    <Dropdown
      showOnlyIcon
      icon={User}
      activatorProps={{
        variant: "none",
        className: "size-6 cursor-pointer",
      }}
      iconSizes={{ className: "size-6 stroke-white flex-none" }}
      className={twMerge("rounded", className)}
      menuProps={{ className: "w-fit" }}
      {...props}
    >
      <Dropdown.Item className="p-0 border-b border-gray-50">
        <Link href="/login" className={LINK_STYLE}>
          {t("users.logIn")}
        </Link>
      </Dropdown.Item>
      <Dropdown.Item className="p-0">
        <Link href="/register" className={LINK_STYLE}>
          {t("register")}
        </Link>
      </Dropdown.Item>
    </Dropdown>
  );
};

UserCtas.displayName = "UserCtas";

UserCtas.propTypes = {
  children: PropTypes.elementType,
  activator: PropTypes.elementType,
  activatorSizes: PropTypes.shape({}),
  shop: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
  messageNumber: PropTypes.number,
  className: PropTypes.string,
};

export default UserCtas;
